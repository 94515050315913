import { For, Show } from "solid-js";
import type { MentorsCardProps } from "../_model";
import SmsIcon from "../assets/icons/sms.svg";
import LinkedInIcon from "../assets/icons/LinkedIn.svg";
import TwitterIcon from "../assets/icons/Twitter.svg";
import { PrimaryButton } from ":src/components/form/buttons";
import placeholder_img from "../assets/images/person_placeholder.png";
import { global } from ":global";
import { Calendly } from "./calendly";
export function MentorsCard(props: MentorsCardProps) {
  function handleBooKSession() {
    global.store.actions.popop.pushModal({
      title: props.name,
      element: () => <Calendly url={props.calendly} />,
    });
  }

  return (
    <div class="relative w-full min-h-19rem max-h-19rem w-26rem flex flex-col rounded-xl bg-white px-3 py-1 overflow-hidden border-1 border-gray-300 border-solid">
      <div class="flex-1">
        <div class="flex justify-start items-center gap-4 pt-3 ">
          <div class="w-fit max-w-full">
            <Show
              fallback={
                <img
                  width={100}
                  height={100}
                  class="w-100px h-100px rounded-full object-cover"
                  src={placeholder_img}
                  alt="img"
                />
              }
              when={props?.image}
            >
              <img
                width={100}
                height={100}
                class="w-100px h-100px rounded-full object-cover"
                src={props?.image}
                alt="img"
              />
            </Show>
          </div>
          <div class="w-60%">
            <h2 class="text-black font-bold text-[18px] py-1 md:py-0 ">{props?.name ?? "No Name"}</h2>

            <div class="flex justify-start items-center gap-2 pt-2">
              <a href={`mailto:${props?.email}`}>
                <SmsIcon class="bg#p px-5px rounded-sm w-27px h-27px" />
              </a>
              <a href={props?.twitter} target="_blank">
                <TwitterIcon class="bg#p px-5px rounded-sm w-27px h-27px" />
              </a>
              <a href={props?.linkedin} target="_blank">
                <LinkedInIcon class="bg#p px-5px rounded-sm w-27px h-27px" />
              </a>
            </div>
            <p class="text-[14px] py-1 md:py-0 text#txtgray text-start">{props?.track ?? "No track"}</p>
          </div>
        </div>
      </div>
      <p class=" w-full text-[14px] px-1 h-full  text#txtgray text-start py-3 overflow-y-auto scrollbar">
        {props?.bio}
      </p>
      <PrimaryButton
        onClick={handleBooKSession}
        label="Book session"
        classes="!w-full !max-w-full !px-4 !mx-auto !py-2 !text-[16px] !my-2  "
      />
    </div>
  );
}
